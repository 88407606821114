import { useCallback, useEffect, useMemo } from "react";
import isArray from "lodash/isArray";
import isEqual from "lodash/isEqual";
import PropTypes from "prop-types";

import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";

import { useFormContext } from "../../Form/Form";
import useFormValue from "../../Form/hooks/useFormValue";
import SelectAllSelectNoneButtons from "../../Form/Select/SelectAllSelectNoneButtons";
import Select from "./FormSelect";
import { useOldSession } from "@clearpoint/old-session/index";

let propTypes = {
	compactFlag: PropTypes.bool,
	userMultipickFlag: PropTypes.bool,
	"data-testid": PropTypes.string,
	filter: PropTypes.func,
	hideSelectAllSelectNoneFlag: PropTypes.bool,
	includeCurrentFlag: PropTypes.bool,
	includeNotDefinedFlag: PropTypes.bool,
	name: PropTypes.string,
	placeholder: PropTypes.string,
};

let FormSelectScorecardAdmin = ({
	compactFlag,
	userMultipickFlag,
	"data-testid": dataTestId,
	hideSelectAllSelectNoneFlag,
	includeCurrentFlag,
	includeNotDefinedFlag,
	includeEmailFlag,
	multipleFlag,
	name,
	placeholder,
	...props
}) => {
	let translate = useTranslate();
	let { setSession } = useOldSession();
	let { get } = useOldQueryStore();
	let profile = get({ object: "profile" });
	let userList = get({ object: "user" });
	let { setFormValue } = useFormContext();
    
	let options = useMemo(() => {
		if (userList === undefined || profile === undefined) return;
		if (!userList) return [];
		let optionsArray = [...userList];
		if (includeCurrentFlag) {
			optionsArray.unshift({ userId: -1, fullName: translate("select.currentUser") });
		}
		if (includeNotDefinedFlag) {
			optionsArray.unshift({ userId: 0, fullName: translate("global.notDefined") });
		}
		optionsArray = optionsArray.filter((x) => x.userTypeId === 1 || x.userTypeId === 9);
		let options = optionsArray.map((user) => ({
			value: user.userId,
			label: user.fullName + (includeEmailFlag && user.email ? ` (${user.email})` : ""),
		}));
		return options;
	}, [includeCurrentFlag, includeEmailFlag, includeNotDefinedFlag, profile, translate, userList]);

	let selectAll = useCallback(() => {
		setFormValue(
			name,
			options.map((x) => x.value)
		);
	}, [options, name, setFormValue]);

	let formValue = useFormValue(name);
	let noOptionsFlag = !isArray(options) || options.length === 0;

	useEffect(() => {
		if (userMultipickFlag && formValue !== undefined && !noOptionsFlag) {
			let value = isArray(formValue) ? formValue : JSON.parse(formValue);
			if (!isEqual(value, formValue)) {
				setFormValue(name, value);
			}
		}
	}, [userMultipickFlag, name, noOptionsFlag, setFormValue, formValue]);

	useEffect(() => {
		setSession({ userOptions: options });
	}, [options, setSession]);

	return (
		<>
			<Select
				data-testid={dataTestId}
				placeholder={
					placeholder ||
					(props.multipleFlag ? translate("edit.selectUsers") + "..." : translate("edit.selectUser"))
				}
				name={name}
				options={options}
				multipleFlag={multipleFlag}
				hideRemoveAllFlag
				{...props}
			/>
			{multipleFlag && !hideSelectAllSelectNoneFlag && (
				<SelectAllSelectNoneButtons
					compactFlag={compactFlag}
					marginBottom={props.marginBottom}
					name={name}
					selectAll={selectAll}
				/>
			)}
		</>
	);
};

FormSelectScorecardAdmin.propTypes = propTypes;

export default FormSelectScorecardAdmin;
