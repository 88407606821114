import { formatMoney, formatNumber as accountingFormatNumber } from "accounting-js";
import cloneDeep from "lodash/cloneDeep";
let formatNumber = ({ decimalPlaces, number, type, currencyCode, currencyList, numberFormat }) => {
	numberFormat = cloneDeep(numberFormat);
	if (numberFormat) return formatMoney(number, numberFormat);
	if (!currencyList && currencyCode) return;
	let currencySymbolLookup = currencyList?.reduce((acc, x) => {
		return {
			...acc,
			[x.currencyCode]: x.symbol,
		};
	});

	let thousand, decimal, format;
	let formatRouter = {
		currency: () => {
			if (["USD", "AUD", "CAD", "MIXED"].includes(currencyCode)) {
				thousand = ",";
				decimal = ".";
			} else {
				thousand = ".";
				decimal = ",";
			}

			format = "%s%v";
			return formatMoney(number, currencySymbolLookup[currencyCode], 2, thousand, decimal, format);
		},
		default: () => accountingFormatNumber(number, decimalPlaces ?? 2, ",", "."),
		integer: () => accountingFormatNumber(number, 0, ",", "."),
		percent: () => accountingFormatNumber(number, decimalPlaces ?? 2, ",", ".") + "%",
	};
	return formatRouter[type || "default"]();
};
export default formatNumber;
